import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Breadcrumbs from "../components/navigation/Breadcrumbs"
import Title from "../components/Title"
import PageComponents from "../components/strapiDynamicZone/PageComponents"

const StandardPage = ({ data, pageContext }) => {
  const {
    metaPageDesc,
    metaPageTitle,
    headerImage,
    slug,
    title,
    body,
    published_at,
  } = data.news
  const currentPage = {
    slug,
    title,
  }
  const breadcrumbs = pageContext.breadcrumbs
  const image = headerImage && getImage(headerImage.localFile)

  return (
    <Wrapper>
      <Layout>
        <Seo title={`${metaPageTitle} | AWIS`} description={metaPageDesc} />
        {headerImage && (
          <GatsbyImage
            image={image}
            className="header-img"
            alt="header image"
          />
        )}

        <div className="page-margins">
          <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />
          <div className="page-template">
            <Title title={title} published_at={published_at} />

            <PageComponents styleClass={"page-content"} body={body} />
          </div>
        </div>
      </Layout>
    </Wrapper>
  )
}

export default StandardPage

const Wrapper = styled.section`
  .header-img {
    height: 24rem;
    width: 100%;
    z-index: 0;
  }
  .page-content {
    width: 100%;
    margin-bottom: 8rem;
  }

  /* Media Queries */
  @media screen and (min-width: 760px) {
    .top-sub-menu {
      display: none;
    }
  }
`

export const query = graphql`
  query GetSingleArticle($slug: String) {
    news: strapiArticles(slug: { eq: $slug }) {
      published_at(formatString: "ll")
      metaPageTitle
      metaPageDesc
      slug
      title
      body
      headerImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
